<template>
    <div class="sheet">
      <div class="Toptitle">
          <span>慢病随访记录单2</span>
      </div>
      <div class="Content">
          <table  style="width:100%" border="1" cellspacing="0" cellpadding="15" bordercolor="#ccc">
              <tr>
                  <td colspan="2">
                      <div class="BasicInformation">
                          <span>姓名：<span class="info">{{userInfo.name}}</span></span>
                          <span>性别：<span class="info">{{userInfo.gender}}</span></span>
                          <span>年龄：<span class="info">{{age(userInfo.birthDate || '')}}</span></span>
                          <!-- <br> -->
                          <span>手机号：<span class="info">{{hideTelephone(userInfo.phoneNumber)}}</span></span>
                          <span>随访健康师：<span class="info">{{ formData?.createdBy?.nickname }}</span></span>
                          <span>随访日期：<span class="info">{{ formData?.sendAt?.slice(0,10) }}</span></span>
                      </div>
                  </td>
              </tr>
              <tr id="myHint" v-if="formData.hints">
                  <td class="table_left">填写提示</td>
                  <td id="hints" class="table_right">{{ formData.hints }}</td>
              </tr>
              <tr>
                  <td class="table_left">检查项目</td>
                  <td class="table_right">
                      <span v-for="item in formData.projects" :key="item.index">
                          【{{ item.name }}】
                      </span>
                  </td>
              </tr>
              <tr>
                  <td class="table_left">上传报告</td>
                  <td class="table_right" style="color: #409EFF;">
                      <van-uploader
                          multiple
                          accept="image"
                          v-model="files"
                          :after-read="(file) => afterRead(file, false)"
                          @delete="deleteImg"
                          :max-count="9"
                          :max-size="10 * 1024 *1024"
                          @oversize="onOversize"
                          :upload-format="'file'"
                          />
                      <!-- <input type="file" ref="fileInput" @change="handleFileChange" multiple/> -->
                  </td>
              </tr>
          </table>
          <div class="Mytitle">指标填写</div>
          <div id="form" class="Indexs">
              <div class="item" v-for="item in items" :key="item.index">
                  <div class="itemTitle">
                      <span style='color: red' v-if="item.required">*</span>
                      {{ item.name }}
                      <!-- <text class="IndexName"><span></span></text> -->
                  </div>
                  <div class="Index">
                      <div style="width:100%;display:flex;">
                          <!-- <span v-if="item.type==='input'" style="display:flex;"> -->
                          <input v-if="item.type==='input'" :required='item.required' class="IndexInput" type="number" v-model="item.value"/>
                          <span class="unit" v-if="item.type==='input'">{{item.unit}}</span>
                          <!-- </span> -->
                          <textarea v-if="item.type==='textarea'" :required='item.required' class="textarea IndexInput" v-model="item.value"></textarea>
                          <select v-if="item.type==='options'" :required='item.required' style="height:35px;width:100%" v-model="item.value">
                             <option v-for="option in item.options" :key="option.index">{{ option }}</option>
                          </select>
                          <!-- <div v-if="item.type==='upload'" class="Notes">请在上方统一上传</div> -->
                          <van-uploader v-if="item.type==='upload'" :required='item.required' :after-read="(file) => afterRead(file, item)" :upload-text='item.name' :max-count="1" v-model="item.value"/>
                      </div>
                      <div class="Notes" v-if="item.remark">备注：{{ item.remark }}</div>
                  </div>
              </div>
          </div>
          <div style="width: 98%;">
              <h3 class="Mytitle">症状描述</h3>
              <textarea class="IndexInput symptom" placeholder="请输入你最近的症状情况" v-model="symptom"></textarea>
          </div>
          <h3 class="Mytitle">用药情况</h3>
          <table style="width:100%" cellspacing="0" cellpadding="15" bordercolor="#eee">
              <thead style="background-color: #eee;">
                  <td>药品名称</td>
                  <td>使用剂量</td>
                  <!-- <td>不良反应</td> -->
              </thead>
              <tbody id="myTable">
                  <tr v-for="medicine in drugList" :key="medicine.index">
                      <td><input class="IndexInput drugName" placeholder="请输入药品名" v-model="medicine.drugName"/></td>
                      <td><input class="IndexInput drugDose" placeholder="请输入使用情况" v-model="medicine.drugDose"/></td>
                      <!-- <td><textarea class="IndexInput reaction" placeholder="请输入不良反应" v-model="medicine.reaction"></textarea></td> -->
                  </tr>
              </tbody>
              
          </table>
          <button class="addDrug" @click="addDrug">+ 添加药品</button>
          <button @click="submitForm" class="submit-button">提交</button>
        </div>
    </div>
  </template>
  
  <script>
  import { Toast } from 'vant';
  export default {
    data () {
      return {
        formData: {},
        userInfo: {},
        symptom: '', // 症状
        drugList: [], // 药物
        reportFiles: [],
        files: [],
        items: []
      }
    },
    methods: {
      init () {
        this.$http.get(`/api/p/${window.fid}`,{
          }).then(res => {
            if(res.status===200){
              this.formData = res.data.data
              this.items = res.data.data.items.map(item => {
                if(item.type==='upload') {
                    return { ...item, value: []}
                } else { return item }
              })
              this.userInfo = res.data.data.customer.baseInfo
              this.drugList = this.formData.medicines.map(item => {
                return { drugName: item, drugDose:'', reaction:''  }
          })
          }
        })
      },
      age(dateStr) {
          if (dateStr) {
              let year = parseInt(dateStr.substr(0, 4))
              let month = parseInt(dateStr.substr(5, 7))
              let day = parseInt(dateStr.substr(8, 10))
              let now = new Date()
              let nowYear = now.getFullYear()
              let nowMonth = now.getMonth() + 1
              let nowDay = now.getDate()
              let age = nowYear - year;
              if (nowMonth < month) {
                  age -= 1;
                  return age+'岁';
              }
              if (nowMonth > month) {
                  return age+'岁';
              }
              // nowMonth == month
              if (nowDay < day) {
                  age -= 1;
                  return age+'岁';
              }
              if (nowDay > day) {
                  return age+'岁';
              }
              // nowDay == day
              return age+'岁';
          }
          return null
      },
      hideTelephone(tel) {
          if (tel) {
              if (tel.length >= 11) {
                  return tel.substr(0, 3) + "****" + tel.substr(7)
              }
          }
          return '-'
      },
      afterRead(files,item){
        console.log(item);
          const formData = new FormData() 
          if (files.length>0) {
              for (let i =0; i<files.length;i++) {
                  formData.append('file', files[i].file)
                  this.uploadFiles(formData)
              }
          } else {
              formData.append('file', files.file)
              this.uploadFiles(formData,item)
          }
          
      },
      uploadFiles(formData,item) {
          this.$http.post("/api/files/upload/image",formData,
          {
              headers: {"content-type": "multipart/form-data"},
          }
          )
          .then(response => {
              if (response.status === 200) {
                if (item) {
                    item.value = [response.data.data]
                    console.log('11', item);
                } else {
                    this.reportFiles.push(response.data.data)
                }
              }
          })
          .catch(error => {
              console.log('error',error)
          })
      },
      deleteImg (event,index) {
          const deleteIndex= index.index
          this.reportFiles = this.reportFiles.filter((item, index) => {
              if(index!==deleteIndex){
                  return true
              }
          })
          console.log('this.reportFiles',this.reportFiles);
      },
      onOversize(file) {
        console.log(file);
        Toast('文件大小不能超过10M');
      },
      addDrug() {
          const drug = { drugName: '', drugDose:'', reaction:''  }
          this.drugList.push(drug)
      },
      submitForm(){
          if (window.upload_status === 'uploading') {
              alert("文件上传中，请稍等...")
              return
          }
          for (let item of this.items) {
              if (item.required && (item.value === null || item.value === '') && this.reportFiles.length === 0) {
                console.log('item',item);
                  alert(`【${item.name}】为必填项`)
                  return
              }
          }
          const payload = {
              items: this.items.map(i => {
                if(i.type==='upload'){
                    const text = JSON.stringify(i.value)
                    return {id: i.id, value: text}
                } else {
                    return {id: i.id, value: i.value}
                }
              }),
              drugList: this.drugList,
              reportFiles: this.reportFiles,
              symptom: this.symptom
          }
          this.$http({
              url: '/api/p/' + window.fid,
              method: 'POST',
              data: payload, 
              headers: {
                  'Content-Type': 'application/json; charset=UTF-8'
              }
          }).then(res => {
              console.log('提交结果',res);
              alert("提交成功")
              window.opener = null
              window.open('', '_self')
              window.close()
          }).catch( err =>{
            alert(err.response.data.message)
            })
          console.log(this.formData.items);
          console.log(this.symptom);
          console.log(this.drugList);
          console.log(this.reportFiles);
      }
    },
    mounted () {
      const url = window.location.href
      const urlSps = url.split('/')
      const param = urlSps[urlSps.length - 1]
      let fid = param.split('?')[0]
      console.log(urlSps, param, fid);
      window.fid = fid
    //   window.fid = 'C'
      if (!window.fid) {
        return
      }
      this.init()
    }
  }
  </script>
  
  <style>
  body {
        /* background-image: url("./pc.jpg"); */
        /* background-size:cover; */
        background: #eef;
    }
  
  .sheet {
      width: 80%;
      border: 1px solid #666;
      background-color: white;
      margin: 30px auto;
  }
  
  .item{
      width: 50%;
  }
  
  .Toptitle {
      width: 100%;
      min-height: 100px;
      /* background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%); */
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #666;
  }
  
  .Toptitle span {
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: 0.35rem;
  }
  
  .Content {
      width: 85%;
      margin: 0px auto;
      padding: 20px 0 50px 0;
      background-color: white;
  }
  
  .Mytitle{
      text-align: left;
      font-weight: bold;
      font-size: 17px;
      margin: 20px 0 10px 0;
      border-left: 5px solid #409EFF;
      padding: 0px 6px;
  }
  .BasicInformation>span {
      display: inline-block;
      color: #999;
      width: 33%;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      text-align: left;
  }
  
  .info {
      color: #222;
      /* float: right; */
  }
  
  .tip {
      color: #7F7F7F;
      margin: 20px 0;
  }
  .table_left{
      color: #666;
      width: 12%;
      text-align: center;
      /* border: 1px solid #555; */
      background-color: #eee;
  }
  .table_right{
      color: #666;
      font-size: 15px;
      text-align: left;
  }
  
  .Indexs {
      width: 100%;
      /* height: 300px; */
      border: 1px solid #ccc;
      /* column-count: 2;
      -moz-column-count:2;
      -webkit-column-count:2;
      -moz-column-gap:0px;
      -webkit-column-gap:0px;
      column-gap:0px; */
      display: flex;
      flex-wrap: wrap;
  }
  
  .Indexs > div {
      border-bottom: 1px solid #ccc;
      min-height: 70px;
      /* min-width: 50%; */
      display: flex;
  }
  
  .Index {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 12px;
  }
  
  .itemTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      padding: 10px;
      width: 40%;
      /* height: 70px; */
      /* line-height: 70px; */
      text-align: center;
      color: #409EFF;
      background-color: #ECF5FF;
  }
  
  .IndexInput {
      height: 30px;
      width: 100%;
      /* background-color: #f0f0f0; */
      border: 1px solid #D6D6D6;
      position: relative;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      outline: none;
      resize: vertical;
  }
  .symptom{
      height: 80px;
      background-color: #eee;
      padding: 8px;
  }
  .textarea{
      height: 50px;
  }
  .IndexInput:focus {
      border: none;
      box-shadow:  0 0 0 1px #42A7FF,0 0 0 2px #BDE7FF;
  }
  
  .symptom:focus {
      border: none;
      box-shadow:  0 0 0 1px #42A7FF,0 0 0 2px #BDE7FF;
  }
  textarea, select {
      min-width: calc(100% - 10px);
      border: 1px solid #D6D6D6;
      resize: none;
  }
  
  .upload {
      width: 100%;
  }
  
  .unit {
      font-size: 10px;
      color: #7F7F7F;
      background-color: #FAFAFA;
      /* position: absolute; */
      border: 1px solid #D6D6D6;
      border-left: none;
      top: 0;
      right: 0;
      display: table-cell;
      white-space: nowrap;
      padding: 8px 12px;
  }
  
  .Notes {
      color: #9D9D9D;
      font-size: 11px;
      margin-top: 5px;
      text-align: left;
  }
  .addDrug{
      width: 100%;
      margin: 10px 0;
      height: 30px;
      border: 1px dashed #ccc;
      background-color: #fff;
  }
  .addDrug:hover{
      color: #40a9ff;
      border-color: #40a9ff;
  }
  .submit-button {
      display: block;
      margin: 50px auto;
      padding: 0.8em 6em;
      font-size: 1rem;
      letter-spacing: 3px;
      font-weight: 500;
      color: #fff;
      background-color: #40A8FF;
      border: none;
      border-radius: 30px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease 0s;
      cursor: pointer;
  }
  
  .submit-button:hover {
      background-color: #23c483;
      box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
      transform: translateY(-7px);
  }
  
  .submit-button:active {
      transform: translateY(-1px);
  }
  
  
  /**手机适配**/
  @media only screen and (max-width: 900px) {
      .sheet {
          width: 100%;
          margin: 0px auto;
      }
  
      .Toptitle span {
          font-size: 1.6rem;
          letter-spacing: 0.35rem;
      }
  
      .Indexs > div {
          min-width: 100%;
      }
  
      .BasicInformation span {
          width: 49%;
          font-size: 13px;
      }
  
      .BasicInformation {
          /* border: 1px solid #555; */
          /* border-style: inset; */
          /* padding: 15px 20px; */
      }
      .table_left{
          font-size: 14px;
      }
      .table_right{
          font-size: 13px;
      }
      .Mytitle{
          font-size: 15px;
          border-left: 4px solid #409EFF;
      }
  
  }
  </style>
  